import React from 'react'
import "./Footer.css"
import {HiOutlinePhone,HiOutlineMail,HiOutlineLocationMarker} from "react-icons/hi";
import {FaFacebookF,FaTelegramPlane,FaInstagram,FaYoutube,FaRss} from "react-icons/fa";
import { useTranslation } from 'react-i18next';

function FooterTop({image,desc,name}){
  return(
    <div className="footer-top">
      <div className="footer-top-item">
        <img src={image} alt="" />
        <div className="footer-top-item-text">
          <h6>{name}</h6>
          <p>{desc}</p>
        </div>
      </div>
  </div>
  )
}

export default function Footer() {
  const {t} = useTranslation();
  return (
    <div className='footer-container'>

      <div className="footer-middle-container">
        <div className="footer-middle-item">
          <h5>{t('footerLang.footer18')}</h5>
            <ul>
              <li><a href="tel:+998334724848"><HiOutlinePhone style={{fontSize:"23px",marginRight:"10px"}}/>+99833 472 48 48</a></li>
              <li><a href="tel:+998338440555"><HiOutlinePhone style={{fontSize:"23px",marginRight:"10px"}}/>+99833 844 05 55</a></li>
              <li><a href="tel:+998882191000"><HiOutlinePhone style={{fontSize:"23px",marginRight:"10px"}}/>+99888 219 10 00</a></li>
                            <li><a href="https://maps.app.goo.gl/WZwK1CnStQQKUHnL7?g_st=com.google.maps.preview.copy"><HiOutlineLocationMarker style={{fontSize:"23px",marginRight:"10px"}}/>Samarkand,kaftarxona</a></li>
            </ul>
        </div>
        <div className="footer-middle-item">
          <h5>{t('footerLang.footer19')}</h5>
           <div className="networks-container">
              {/* <a href="https://www.facebook.com/asaxiyshop/" target="_blank"><FaFacebookF/></a> */}
              <a href="https://t.me/rs_stroyuz" target="_blank"><FaTelegramPlane/><small> telegram</small></a><br />
              <a href="https://www.instagram.com/rs_stroy.uz/" target="_blank"><FaInstagram/> <small>instagram</small></a>
              {/* <a href="https://www.youtube.com/channel/UC0UaPm4AlSkAU7U88H8jhhQ" target="_blank"><FaYoutube/></a> */}
              {/* <a href="https://asaxiy.uz/uz/rss" target="_blank"><FaRss/></a> */}
           </div>
        </div>
      </div>
      {/* <div className="footer-bottom-container">
        <h5>{t('footerLang.footer20')}</h5>
        <img src="https://asaxiy.uz/custom-assets/images/apelsin-logo.svg" alt="" />
        <img src="https://asaxiy.uz/custom-assets/images/payme.svg" alt="" />
        <img src="https://asaxiy.uz/custom-assets/images/visa.svg" alt="" />
        <img src="https://asaxiy.uz/custom-assets/images/mastercard.svg" alt="" />
        <img src="https://asaxiy.uz/custom-assets/images/humo.svg" alt="" />
        <img src="https://asaxiy.uz/custom-assets/images/uzcard.svg" alt="" />
          <div className="footer-middle-item-responsive">
            <h6>Biz ijtimoiy tarmoqlarda:</h6>
                <a href="https://www.facebook.com/asaxiyshop/" target="_blank"><FaFacebookF/></a>
                <a href="https://t.me/asaxiyuz" target="_blank"><FaTelegramPlane/></a>
                <a href="https://www.instagram.com/asaxiyshop/" target="_blank"><FaInstagram/></a>
                <a href="https://www.youtube.com/channel/UC0UaPm4AlSkAU7U88H8jhhQ" target="_blank"><FaYoutube/></a>
                <a href="https://asaxiy.uz/uz/rss" target="_blank"><FaRss/></a>
        </div>
      </div> */}
      {/* <div className="footer-bootom-responsive">
          <img src="https://asaxiy.uz/custom-assets/images/apelsin-logo.svg" alt="" />
          <img src="https://asaxiy.uz/custom-assets/images/payme.svg" alt="" />
          <img src="https://asaxiy.uz/custom-assets/images/visa.svg" alt="" />
          <img src="https://asaxiy.uz/custom-assets/images/mastercard.svg" alt="" />
          <img src="https://asaxiy.uz/custom-assets/images/humo.svg" alt="" />
          <img src="https://asaxiy.uz/custom-assets/images/uzcard.svg" alt="" />
      </div> */}
 <a href="https://uptuz.com/" target="_blank"><p style={{textAlign:"center", color:"#fff",marginTop:"30px"}}>&copy;2024 - UPTUZ </p></a>
    </div>
  )
}

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./Slide.css"

// Import Swiper styles
import "swiper/css";
import { Autoplay,Mousewheel } from "swiper";

export default function Slide() {
  return (
    <div className="slide-swiper-container">
         <Swiper watchSlidesProgress={true} 
           slidesPerView={2.3}
           centeredSlides={false}
           mousewheel={true}
           autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        loop={true}
        loopFillGroupWithBlank={true}
        modules={[Autoplay,Mousewheel]}
        spaceBetween={0} 
          breakpoints={{
            456:{
              slidesPerView: 3,
              spaceBetween: 20,
            },
            540:{
              slidesPerView: 3,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 4.5,
              spaceBetween: 100,
            },
            768: {
              slidesPerView: 5.5,
              spaceBetween: 100,
            },
            1024: {
              slidesPerView: 8,
              spaceBetween: 50,
            }, 
          }}
         >
            <SwiperSlide className="swiper-slide2" ><img src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQ4Nnr0EXlQ19-PacJfNJJy9yrWga0ARxU-yfCnYqpjf6Ft1n-6" alt="brend-slide" /></SwiperSlide>
            <SwiperSlide className="swiper-slide2"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmziGH0gmd5py0uNsGm1yV88ZXOZQNHshZ3w&s" alt="brend-slide" /></SwiperSlide>
            <SwiperSlide className="swiper-slide2"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1US_NWlsPQ952j6LVsYZXAswQGqVbeDp6_w&s" alt="brend-slide" /></SwiperSlide>
            <SwiperSlide className="swiper-slide2"><img src="https://gflex.ru/upload/iblock/fa4/fa46a2f2e87ebc3816aeecfc77d70529.png" alt="brend-slide" /></SwiperSlide>
            <SwiperSlide className="swiper-slide2"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPbAbxJ9rTyCqx18WuDGLTxCWjtuE3YoAO5g&s" alt="brend-slide" /></SwiperSlide>
            <SwiperSlide className="swiper-slide2"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNvz1lSnz3RKW7x0A4s4euLcp7HUCBUWCNfw&s" alt="brend-slide" /></SwiperSlide>
            <SwiperSlide className="swiper-slide2"><img src="https://top.uz/upload/iblock/a9d/a9d9c7994cdee9aa95175349cabfb891.jpg" alt="brend-slide" /></SwiperSlide>
            <SwiperSlide className="swiper-slide2"><img src="https://e-pos.uz/uploads/brands/Ventum-1662383339.png" alt="brend-slide" /></SwiperSlide>
        </Swiper>
    </div>
  )
}

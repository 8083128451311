import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ThreeDots } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import Card from '../components/Cards/Card'
import axios from './../service/api'
import './firstcategory.css'

function Firstcategory() {
	let { id } = useParams()
	const { t, i18n } = useTranslation()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(`categories/${id}/`)
				setData(res.data.prod_obj)
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [id])

	if (loading) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: 'white', // Optional: Adds a light overlay
					zIndex: 1000, // Ensures the loader is on top of everything
				}}
			>
				<ThreeDots
					height='130'
					width='130'
					radius='9'
					color='#17b978'
					ariaLabel='three-dots-loading'
					visible={true}
				/>
			</div>
		)
	}

	return (
		<div className='category-1'>
			{data?.map(item => (
				<Card
					key={item.id}
					item={item}
					translation={item.translation}
					title={item.title}
					image={item.image_main}
					price={item.price}
					id={item.id}
					true_price={item.true_price}
				/>
			))}
		</div>
	)
}

export default Firstcategory

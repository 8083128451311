// import Section from '../components/section/Section'
import Carausel from '../components/Carausel/Carousel'
// import Info from '../components/CompanyInfo/Info'
import Slide from '../components/Carausel/Slide'

export default function Home() {
  return (
    <div>
      <Carausel/>
      <Slide/>
      {/* <Section/> */}
      {/* <Info/> */}
    </div>
  )
}

import { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Footermobile from './components/section/Footermobile'
import Cart from './pages/Cart'
import Discounts from './pages/Discounts'
import Favourites from './pages/Favourites'
import Firstcategory from './pages/firstcategory'
import Home from './pages/Home'
import NewProduct from './pages/NewProduct'
import News from './pages/News'
import OrderPay from './pages/OrderPay'
import OrderPayStatus from './pages/OrderPayStatus'
import ProductDetail from './pages/ProductDetail'
import Sport from './pages/Sport'
import Telephones from './pages/Telephones'
import Tv from './pages/Tv'
function App() {
	const [loader, setLoader] = useState(false)
	const Loader = () => {
		return (
			<div
				style={{
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<ThreeDots
					height='130'
					width='130'
					radius='9'
					color='#17b978'
					ariaLabel='three-dots-loading'
					wrapperStyle={{}}
					wrapperClassName=''
					visible={true}
				/>
			</div>
		)
	}
	useEffect(() => {
		setLoader(true)
		setTimeout(() => {
			setLoader(false)
		}, 1000)
	}, [])
	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div className='App'>
					<Header />
					<ToastContainer />
					<Routes>
						<Route path='/:id' element={<Firstcategory />} />
						<Route path='/' element={<Home />} />
						<Route path='/product/:id' element={<ProductDetail />} />
						<Route path='/news' element={<News />} />
						<Route path='/newProduct' element={<NewProduct />} />
						<Route path='/Discounts' element={<Discounts />} />
						<Route path='/telephones' element={<Telephones />} />
						<Route path='/tv' element={<Tv />} />
						<Route path='/sports' element={<Sport />} />
						<Route path='/order-pay' element={<OrderPay />} />
						<Route path='/order-pay-status' element={<OrderPayStatus />} />
						<Route path='/favourites' element={<Favourites />} />
						<Route path='/Cart' element={<Cart />} />
					</Routes>
					<div className='slider'>
						{/* <Slide/> */}
						{/* <Store/> */}
					</div>
					<Footermobile />
					<Footer />
					{/* <Modal/> */}
				</div>
			)}
		</>
	)
}

export default App
